<template>
  <el-container>
    <div class="el-header" style="width: 100%;">
      <el-header height="80px">
        <div style="height : 80px;" class="div div-logo">
          <a href="http://yideio.cn">
            <img src="../assets/logo.png" alt="ydcoin-logo" class="logo">
          </a>
        </div>
        <!-- <h1 class="h1 logo">谊德量化</h1> -->
        <div class="div div-button">
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
            <el-menu-item index="1" class="el-menu-demo">首页</el-menu-item>
            <el-menu-item index="2" class="el-menu-demo">
              <a href="http://yideio.vip/download/YdCoin.zip" style="text-decoration:none;">软件下载</a>
            </el-menu-item>
            <el-menu-item index="3" class="el-menu-demo">
              <router-link to="/register">注册</router-link>
            </el-menu-item>
            <el-menu-item index="4" class="el-menu-demo">
              <el-link :underline="false" href="./about.html" target="_blank">关于我们</el-link>
              <!-- <router-link to="/test">关于我们</router-link> -->
            </el-menu-item>
          </el-menu>
        </div>
      </el-header>
    </div>
    <el-main>
      <div class="picture" style="margin-bottom: 50px;">
        <!-- <el-carousel :interval="4000" type="card" :height="bannerHeight + 'px'">
            <el-carousel-item v-for="item in imagebox" :key="item.id">
              <img :src="item.idView" class="image" ref="bannerHeight" @load="imgLoad">
            </el-carousel-item>
          </el-carousel> -->
        <el-carousel :interval="5000" arrow="always" :height="bannerHeight + 'px'">
          <el-carousel-item v-for="item in imagebox" :key="item.id">
            <a href="http://yideio.vip/download/YdCoin.zip">
              <img :src="item.idView" class="image" ref="bannerHeight" @load="imgLoad">
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="main">
        <div class="download">
          <el-link href="http://yideio.vip/download/YdCoin.zip" target="_blank" :underline="false" style="width: 420px">
            <img src="../assets/Windows.png" style="margin: auto;display: block;">
            <div style="padding: 0px;">
              <span style="text-align: center;display: block;">Windows 桌面版（64位）</span>
              <div class="bottom clearfix">
                <!-- <time class="time">{{ currentDate }}</time> -->
                <el-button type="text" class="button">下载到本地</el-button>
              </div>
            </div>
          </el-link>
          <el-link href="http://yideio.vip/download/YdCoin.zip" target="_blank" :underline="false" style="width: 420px">
            <img src="../assets/wechart.jpg">
            <div style="padding: 0px;">
              <span style="text-align: center;display: block;">客服微信</span>
              <div class="bottom clearfix">
                <!-- <time class="time">{{ currentDate }}</time> -->
                <el-button type="text" class="button">联系客服</el-button>
              </div>
            </div>
          </el-link>
          <el-link href="https://dotnet.microsoft.com/download/dotnet-framework/thank-you/net472-offline-installer"
            target="_blank" :underline="false" style="width: 420px">
            <img src="../assets/net.jpg">
            <div style="padding: 0px;">
              <span style="text-align: center;display: block;">运行环境下载</span>
              <div class="bottom clearfix">
                <!-- <time class="time">{{ currentDate }}</time> -->
                <el-button type="text" class="button">下载到本地</el-button>
              </div>
            </div>
          </el-link>
        </div>
        <div class="detail-block">
          <div style="display: inline-block;width: 500px;float: left;">
            <!-- <img src="./assets/Windows.png" style="margin: auto;display: block;"> -->
            <h1 class="text-right" style="margin-top: 100px;">智能高频</h1>
            <p class="text-right">精准趋势判断，多空智能化运行，无惧行情涨跌</p>
            <p class="text-right">支持价格、振幅、区间等多类型预警</p>
            <p class="text-right">运筹帷幄，一切尽在把握</p>
          </div>
          <div style="display: inline-block;width: 700px; float: right;">
            <img src="../assets/right.jpg" style="margin: auto;display: block;">
          </div>
        </div>
        <div class="detail-block">
          <div style="display: inline-block;width: 500px;float: right;">
            <!-- <img src="./assets/Windows.png" style="margin: auto;display: block;"> -->
            <h1 style="margin-top: 100px;">量化交易的本质</h1>
            <p>量化交易即程序化交易，通过既定的策略模型，运算大量的数据</p>
            <p>低买高卖，小单高频，稳定套利</p>
          </div>
          <div style="display: inline-block;width: 500px; float: left;">
            <img src="../assets/left.png" style="margin: auto;display: block;">
          </div>
        </div>
        <div class="information">
          <div class="partner-title">
            <h1 class="h1">使用教程</h1>
            <el-link type="_blank" class="link">更多>></el-link>
          </div>
          <div class="information-card">
            <!-- <el-row>
                <el-col :span="8" v-for="(o, index) in 2" :key="o" :offset="index > 0 ? 2 : 0">

                </el-col>
              </el-row> -->
            <el-card class="box-card" :body-style="{ padding: '10px' }">
              <div slot="header" class="clearfix">
                <span>教程</span>
                <el-button style="float: right; padding: 3px 0" type="text">更多>></el-button>
              </div>
              <div style="display: inline-block;margin-right: 150px;vertical-align:top;">
                <div>
                  <img src="../assets/information.png" alt="">
                </div>
              </div>
              <div style="display: inline-block;vertical-align:top;">
                <div class="text item">
                  <el-link :underline="false" href="./article.html" target="_blank">1. 谊德量化软件注册教程</el-link>
                </div>
                <div class="text item">
                  <el-link :underline="false" href="./article1.html" target="_blank">2. 软件AI金字塔设置步骤及参数详细说明</el-link>
                </div>
                <div class="text item">
                  <el-link :underline="false" href="./article2.html" target="_blank">3. 软件智能高频设置步骤、各项参数以及风控设置说明
                  </el-link>
                </div>
                <div class="text item">
                  <el-link :underline="false" href="./article3.html" target="_blank">4. 关于火币子账户开通教程</el-link>
                </div>
                <div class="text item">
                  <el-link :underline="false" href="./article4.html" target="_blank">5. 关于火币子账户api开通教程</el-link>
                </div>
                <div class="text item">
                  <el-link :underline="false" href="./article5.html" target="_blank">6. 谊德量化软件注意事项</el-link>
                </div>
                <div class="text item">
                  <el-link :underline="false" href="./article6.html" target="_blank">7. 关于腾讯云服务器购买教程</el-link>
                </div>
                <div class="text item">
                  <el-link :underline="false" href="./article7.html" target="_blank">8. 币安子账户开通教程（需购买云服务器，参照云服务器购买教程即可）
                  </el-link>
                </div>
                <div class="text item">
                  <el-link :underline="false" href="./article8.html" target="_blank">9. 关于币安子账户api开通教程</el-link>
                </div>
              </div>
            </el-card>
          </div>
        </div>

        <div class="partner">
          <div class="partner-title">
            <h1 class="h1">合作</h1>
            <!-- <el-link type="_blank" class="link">更多>></el-link> -->
          </div>
          <div class="partner-card">
            <el-card class="box-card">
              <ul class="partner-ul ul">
                <li class="li partner-ul-li">
                  <div style=" background: no-repeat center/100% url(http://yideio.cn/static/image/huobi.jpg);"
                    class="cover"></div>
                  <div class="title">
                    <!-- <img src="https://static.aicoinstorge.com/marketLogo/20180612/152878780254152.png?x-oss-process=image/resize,m_fill,h_108,w_108,limit_0" alt="" class="img"> -->
                    <strong class="text">Huobi</strong>
                  </div>
                </li>
                <li class="li partner-ul-li">
                  <div style=" background: no-repeat center/100% url(http://yideio.cn/static/image/_OKEx.jpg);"
                    class="cover"></div>
                  <div class="title">
                    <!-- <img src="https://static.aicoinstorge.com/marketLogo/20180612/152878780254152.png?x-oss-process=image/resize,m_fill,h_108,w_108,limit_0" alt="" class="img"> -->
                    <strong class="text">OKEX</strong>
                  </div>
                </li>
                <li class="li partner-ul-li">
                  <div style=" background: no-repeat center/100% url(http://yideio.cn/static/image/binance.png);"
                    class="cover"></div>
                  <div class="title">
                    <!-- <img src="https://static.aicoinstorge.com/marketLogo/20180612/152878780254152.png?x-oss-process=image/resize,m_fill,h_108,w_108,limit_0" alt="" class="img"> -->
                    <strong class="text">Binance</strong>
                  </div>
                </li>
                <li class="li partner-ul-li">
                  <div style=" background: no-repeat center/100% url(http://yideio.cn/static/image/bybit.png);"
                    class="cover"></div>
                  <div class="title">
                    <!-- <img src="https://static.aicoinstorge.com/marketLogo/20180612/152878780254152.png?x-oss-process=image/resize,m_fill,h_108,w_108,limit_0" alt="" class="img"> -->
                    <strong class="text">Bybit</strong>
                  </div>
                </li>
                <li class="li partner-ul-li">
                  <div style=" background: no-repeat center/100% url(http://yideio.cn/static/image/aliyun.jpg);"
                    class="cover"></div>
                  <div class="title">
                    <!-- <img src="https://static.aicoinstorge.com/marketLogo/20180612/152878780254152.png?x-oss-process=image/resize,m_fill,h_108,w_108,limit_0" alt="" class="img"> -->
                    <strong class="text">阿里云</strong>
                  </div>
                </li>
              </ul>
            </el-card>
          </div>
        </div>
      </div>
    </el-main>
    <el-footer style="height: 324px;">
      <div class="footer-main">
        <div class="footer-left footer-other">
          <img src="../assets/ydcoin-logo.png" alt="ydcoin-logo" class="logo">
          <p class="h2 fontsize-left">专注量化交易，致力服务于交易的每一位用户</p>
          <p class="h2 fontsize-left">软件全年不间断维护更新，客服24小时在线指导</p>
        </div>
        <div class="h2 footer-feature footer-other">
          <strong>友情链接</strong>
          <ul class="ul footer-ul fontsize-left">
            <li class=" footer-li">火币</li>
            <li class=" footer-li">币安</li>
            <li class=" footer-li">OKEX</li>
            <li class=" footer-li">腾讯云</li>
            <li class=" footer-li">阿里云</li>
          </ul>
        </div>
        <div class="h2 footer-feature footer-other">
          <strong>服务</strong>
          <ul class="ul footer-ul fontsize-left">
            <li class=" footer-li">活动推广</li>
            <li class=" footer-li">商务合作</li>
            <li class=" footer-li">项目/交易所收录</li>
            <li class=" footer-li">资讯内容</li>
            <li class=" footer-li">广场入驻</li>
          </ul>
        </div>
        <div class="h2 footer-feature footer-other">
          <strong>软件下载</strong>
          <ul class="ul footer-ul fontsize-left">
            <li class=" footer-li">
              <el-link :underline="false" href="http://yideio.vip/download/YdCoin.zip" target="_blank"
                style="color: #C1D1E6">Windows版(64位)</el-link>
            </li>
            <li class=" footer-li">
              <el-link :underline="false" href="http://yideio.vip/download/YdCoin.zip" target="_blank"
                style="color: #C1D1E6">Windows版(32位)</el-link>
            </li>
          </ul>
        </div>
        <div class="h2 footer-feature footer-other">
          <strong>联系我们</strong>
          <ul class="ul footer-ul fontsize-left">
            <li class=" footer-li">关于我们</li>
            <li class=" footer-li">微博 YdCoin_com</li>
            <li class=" footer-li">WeChart</li>
            <li class=" footer-li">官方邮箱</li>
          </ul>
        </div>
        <div class="footer-buttom">
          <span>
            Copyright ©2019-2020 AICoin All Rights Reserved.
          </span>
          <a href="http://beian.miit.gov.cn/" class="link">京ICP备 xxxxxxx号-2</a>
          <a href="" class="link">Cookie policy</a>
        </div>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      bannerHeight: "",
      currentDate: new Date(),
      imagebox: [
        {
          id: 0,
          idView: require("../assets/1.jpg"),
        },
        {
          id: 1,
          idView: require("../assets/2.jpg"),
        },
      ],
      information: [
        "ETH 2.0即将发布，但距离正式投入使用还有多久？",
        "16000美元已经是小目标，美联储后续的放水政策才值得期待",
        "BCH再分叉 NODE开发组对垒ABC",
        "币安被美国FBI和国税局盯上被福布斯曝光，币安还能安多久？",
        "一觉醒来，比特币、黄金全涨疯了！",
        "比特币上行创18年以来价格新高，暂未见顶顺势操作",
        "BTC暴涨上热搜，绝大多数人却已错过",
        "比特币再次突破新高，灰度再入7808枚比特币，明庄打明牌。",
      ],
      instructions: [
        { id: 0, msg: "ETH 2.0即将发布，但距离正式投入使用还有多久？" },
        { id: 1, msg: "ETH 2.0即将发布，但距离正式投入使用还有多久？" },
        { id: 2, msg: "ETH 2.0即将发布，但距离正式投入使用还有多久？" },
        { id: 3, msg: "ETH 2.0即将发布，但距离正式投入使用还有多久？" },
        { id: 4, msg: "ETH 2.0即将发布，但距离正式投入使用还有多久？" },
      ],
      activeIndex: "1",
    };
  },
  methods: {
    startHacking() {
      this.$notify({
        title: "It works!",
        type: "success",
        message:
          "We've laid the ground work for you. It's time for you to build something epic!",
        duration: 3000,
      });
    },
    imgLoad() {
      this.$nextTick(() => {
        this.bannerHeight = this.$refs.bannerHeight[0].height;
        console.log(this.bannerHeight);
      });
    },
    mounted() {
      this.imgLoad();
      window.addEventListener(
        "resize",
        () => {
          this.bannerHeight = this.$refs.bannerHeight[0].height;
          this.imgLoad();
        },
        false
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#home {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background-color: #e8edf3;
  min-width: 1323px;
}
.el-container {
  margin: 0px;
  padding: 0px;
  /* height: 100%; */
}
.el-header {
  background-color: #f8f9fc;
  /* color: #333; */
  text-align: center;
  line-height: 80px;
  height: 80px;
  width: 1280px;
  margin: 0 auto;
  padding: 0;
}
.el-header .el-menu-demo {
  background-color: #f8f9fc;
  height: 80px;
  line-height: 80px;
  font-size: 15px;
}
.logo {
  cursor: pointer;
  margin-top: 20px;
  margin-left: 10px;
}
.el-footer {
  background-color: #0c101c;
  color: #333;
  /* text-align: center;
  line-height: 100px;
  height: 250px; */
  height: 250px;
}
.el-footer .footer-left {
  width: 400px;
  height: 250px;
  /* display: inline-block; */
  margin-top: 20px;
}
.el-footer .footer-feature {
  width: 100px;
  /* height: 200px; */
  /* display: inline-block;
  vertical-align: top; */
  padding-top: 40px;
}
.el-footer .footer-main {
  width: 1250px;
  height: 250px;
  margin: 0 auto;
}
.el-footer .footer-other {
  vertical-align: top;
  display: inline-block;
  margin-right: 80px;
}
.el-footer .footer-ul {
  margin-top: 20px;
}
.el-footer .footer-li {
  margin-top: 14px;
  cursor: pointer;
}
.el-footer .footer-buttom {
  margin-top: 13px;
  border-top: 1px solid #9b9ca7;
  line-height: 40px;
  font-size: 12px;
  color: #565d66;
}
.el-footer .footer-buttom .link {
  cursor: pointer;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  color: #565d66;
  margin-left: 10px;
}
.el-footer .fontsize-left {
  font-size: 13px;
  color: #c1d1e6;
}
.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #eff2f5;
  color: #333;
  padding: 0;
}
.el-main .main {
  width: 1323px;
  margin: 0 auto;
}
body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
.h1 {
  font-size: 30px;
  float: left;
  margin-block-start: 0;
  margin-block-end: 0;
}
.h2 {
  color: #f2f4f7;
}
.el-header .div,
.el-header .h1 {
  display: inline;
}
.el-header .div-logo {
  float: left;
  /* margin-top: 15px; */
}
.el-header .div-button {
  /* width : 50%;  */
  height: 100px;
  float: right;
}
/* .el-header .el-row {
  margin-bottom: 20px;
} */
.el-header .el-row :last-child {
  margin-bottom: 0;
}
.el-header .el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  margin-top: 32px;
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
  text-align: center;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
.information-card .box-card {
  width: 1280px;
  height: 400px;
}
.information-card .box-card .text {
  font-size: 14px;
}

.information-card .box-card .item {
  margin-bottom: 15px;
}
.information-card .box-card .info-li {
  margin-top: 14px;
}
.el-main .information {
  height: 500px;
  margin-top: 20px;
}
.el-main .information .link {
  margin-top: 21px;
  margin-left: 5px;
}
.partner-card {
  width: 1323px;
}
.partner-card .box-card {
  /* height: 300px; */
  padding: 0;
}
.partner-title {
  height: 40px;
  margin-bottom: 10px;
}
.partner-card .box-card .partner-ul {
  height: 140px;
  list-style: none;
  margin-left: 35px;
}
.partner-card .box-card .partner-ul-li {
  display: inline-block;
  width: 236px;
  border: 1px solid #f2f4f7;
  cursor: pointer;
  box-sizing: border-box;
}
.partner-ul-li .cover {
  width: 220px;
  height: 102px;
  margin: 8px auto 0 auto;
  display: block;
  box-sizing: border-box;
}
.partner-ul-li .title {
  padding: 0 8px;
  font-size: 12px;
  color: #141722;
  line-height: 18px;
  height: 18px;
  margin-top: 6px;
  box-sizing: border-box;
}
.partner-ul-li .title .img {
  width: 18px;
  height: 18px;
  vertical-align: top;
}
.partner-ul-li .title .text {
  vertical-align: middle;
  margin-left: 4px;
}
.ul,
.li {
  padding: 0;
  margin: 0;
  display: block;
  list-style: none;
}
.download .box-card {
  width: 200px;
  height: 250px;
}
.download {
  width: 1280px;
  height: 250px;
  margin: 0 auto;
  padding-top: 50px;
}
.el-main .main .detail-block {
  height: 650px;
  width: 1280px;
  margin: 0 auto;
  padding-top: 150px;
}
.detail-block .text-right {
  text-align: right;
}
</style>
